/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Tabs, Tab } from 'react-bootstrap';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesCreep: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - Plague Creeper</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_creep.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Dream Realm - Plague Creeper</h1>
          <h2>
            Dream Realm Plague Creeper guide that explains the boss mechanics
            and how to beat it!
          </h2>
          <p>
            Last updated: <strong>17/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Plague Creeper" />
        <p>
          <strong>Plague Creeper</strong> is one of the 8 available Dream Realm
          bosses you will encounter in AFK Journey in Season 3. In this guide we
          will go over the boss mechanics and the best characters and teams to
          use against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 3 Teams" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team (Whale)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/plag_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Krau (Yaphalla)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as the tank and energy regen source.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="hugin" enablePopover /> can
                  be used at high paragon.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <li>
                <AFKCharacter mode="inline" slug="harak" enablePopover /> is the
                main carry (<strong>Supreme+</strong> required).
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> is the
                secondary carry and buffer.
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="elijah-and-lailah"
                  enablePopover
                />{' '}
                acts as buffer and healer.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Surging Spell" /> - Best Performance so far.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #2 - Alt Team (No Twins)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/plag_2.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Isdaturrealhair (PTR)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as the tank and energy regen source.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <li>
                <AFKCharacter mode="inline" slug="harak" enablePopover /> is the
                main carry (<strong>Supreme+</strong> required).
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="talene" enablePopover />
                  can be used as an alternative carry, if you bring Smokey
                  instead of Sonja.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> is the
                secondary carry and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as secondary carry, buffer and healer.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Surging Spell" /> - Best Performance so far.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={<>Team #3 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #3 - Alt Team (Reinier + Harak/Odie)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/plag_3.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Starlord (Analytica)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="hugin" enablePopover /> acts
                as buffer and provides shields.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="mikola" enablePopover /> is
                  a good alternative.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover /> can
                  also be used for the shields.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <li>
                <AFKCharacter mode="inline" slug="harak" enablePopover /> is the
                main carry (<strong>Supreme+</strong> required).
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="odie" enablePopover /> can
                  be used as an alternate carry.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as secondary carry and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sinbad" enablePopover /> acts
                as secondary carry, buffer and debuffer.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Snowman Spell" /> - Best performance so far.
                </li>
                <li>
                  <AFKItem name="Harmonic Spell" /> - Buff-oriented alternative.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team4"
            title={<>Team #4 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #4 - Alt Team (No Celehypo)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/plag_4.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>IndominousLink (Analytica)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover />
                is the main damage carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover /> acts as
                buffer and healer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="hugin" enablePopover /> can
                  be used as an alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="sinbad" enablePopover /> acts
                as secondary carry, buffer and debuffer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  can be used as an alternate.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as secondary carry and buffer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="mikola" enablePopover /> can
                  be used as an alternate.
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as buffer and healer.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Bladesummon Spell" /> - Best overall
                  performance.
                </li>
                <li>
                  <AFKItem name="Harmonic Spell" /> - Good budget alternative.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesCreep;

export const Head: React.FC = () => (
  <Seo
    title="Plague Creeper | AFK Journey | Prydwen Institute"
    description="Dream Realm Plague Creeper guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
